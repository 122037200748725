import React from "react";
import styled from "styled-components";
import Topbar from "src/components/topbar.js";

import SEO from "src/components/seo.js";

const Container = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    margin: 50px 0;
    position: relative;

`;

const Wrapper = styled.div`
    font-family: "URWAccidalia", sans-serif;
    color: white;
    max-width: 650px;
    padding: 40px;
`;

const Block = styled.div`
    margin: 40px 0;

    p {
        font-family: "Univers";
        line-height: 1.2;
        margin: 10px 0;
    }
`;

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <Topbar></Topbar>
            <Container>
                <Wrapper>
                    <Block>
                        <h1>Welcome to HXOUSE!</h1>
                        <p>
                            Our goal is to provide you with all the information you need to enjoy and learn about the event you are attending in person or online, and to connect you with our network in a meaningful way. This privacy policy sets out how HXOUSE uses and protects any information that you give us when you use this website. When you create a HXOUSE account or use HXOUSE, you are agreeing to comply with and be bound by the following privacy policy which together with our terms of use govern the relationship between you and HXOUSE.
                        </p>
                        <p>
                            HXOUSE is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this app, then you can be assured that it will only be used in accordance with this privacy statement. If you submit any data to our app, such as your attendee profile we will use it as part of the mobile event guide which is publicly viewable. Your Login email is not viewable or accessible publicly. Your password is hashed, which means it is not reversible and not viewable in plain text. However, you may contact us at any time to delete or change this data.
                        </p>
                        <p>
                            We may change this policy from time to time with or without notice, effective upon it posting to the App. Your continued use of the App will be considered your acceptance to the revised Privacy Policy. You should check this page from time to time to ensure that you are happy with any changes. The most current version of Privacy Policy, which supersedes all previous versions, can be reviewed by clicking on the Privacy Policy located at the bottom of settings page. HXOUSE is owned and operated by OPEN HXOUSE Inc.
                        </p>
                    </Block>
                    <Block>
                        <h1>WHAT WE COLLECT</h1>
                        <p>
                            Information and content you provide. We collect the content, communications and other information you provide when you use our Service, including when you sign up for an account, create or share content, and message or communicate with others. When you sign up we may collect the following information: name and job title, contact information including email address or other public social media addresses, demographic information such as postal code, preferences and interests, other information relevant to customer surveys and/or offers.
                        </p>
                        <p>
                            Networks and connections. We collect information about the people, and the accounts you are connected to and how you interact with them across our Service, such as people you communicate with.
                        </p>
                        <p>
                            Your usage. We collect information about how you use our Service,, such as the types of content you view or engage with; the features you use; the actions you take; the people or accounts you interact with; and the time, frequency and duration of your activities. For example, we log when you're using and have last used our Service, and what posts, videos and other content you view on our Service. We also collect information about how you use features like our camera.
                        </p>
                    </Block>
                    <Block>
                        <h1>WHAT WE DO WITH INFORMATION WE GATHER</h1>
                        <p>
                            This information is compiled as part of the event guide that you are attending or participating to help delegates make the most of the event they are attending. Unless the event app is a secure/private app that requires login information upon use, this information is not exclusive to the participant of this event and is publicly available online. When you are logged in, your responses to session polls and surveys are not anonymous and accessible only to the event organizers. We use the information we have to deliver our Service, including to personalize features and content and make suggestions for you (such as events you may be interested in or topics you may want to follow) on and off our Service. To create personalized Service that are unique and relevant to you, we use your connections, preferences, interests and activities based on the data we collect and learn from you and others (including any data with special protections you choose to provide); how you use and interact with our Service; and the people, or things you're connected to and interested in on and off our Service. We also use the information we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of our Service, and promote safety and security on and off of HXOUSE Service,
                        </p>
                    </Block>
                    <Block>
                        <h1>SECURITY</h1>
                        <p>
                            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                        </p>
                    </Block>
                    <Block>
                        <h1>HOW WE USE COOKIES</h1>
                        <p>
                            A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                        </p>
                    </Block>
                    <Block>
                        <h1>USAGE STATISTICS</h1>
                        <p>
                            HXOUSE will collect general anonymous data on site usage statistics such as page access, duration of stay, browser or device type. This data is provided by default by your operating internet browser or phone carrier and is collected directly by HXOUSE or through other third party tools such as Google Analytics. HXOUSE also reserves the right to use usage statistics on marketing and publicly available material.
                        </p>
                    </Block>
                    <Block>
                        <h1>LINKS TO OTHER WEBSITES</h1>
                        <p>
                            Our app may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
                        </p>
                    </Block>
                    <Block>
                        <h1>CONTROLLING YOUR PERSONAL INFORMATION</h1>
                        <p>
                            You may choose to restrict the collection or use of your personal information; if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at support@hxousei.com We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
                        </p>
                        <p>
                            This Privacy Policy (“Policy”) is made as of September 21, 2020 (the “Effective Date”) and applies to HXOUSE owned and operated by Open HXOUSE Inc.
                        </p>
                        <p>
                            The purpose of this Policy is to govern our use of information we may learn about you when you visit our Application (the “Site”). In addition to our Policy, the Site is subject to the Terms of Use which governs in any conflict with this Policy. We may change this Policy at any time by notifying you of a new effective date.
                        </p>
                    </Block>
                    <Block>
                        <h1>1. NOTICE</h1>
                        <p>
                            Other Companies. Some activities on our Site may be in conjunction with other companies (“Companies”). You may be asked to provide information that can personally identify you in order to engage in these activities, and that information will be shared with the Companies, whose identity we will disclose to you at the time you provide your information. While our use of your information will be subject to this Privacy Policy, the Companies’ use of the same information will fall under their own privacy policies. If you’re concerned about any particular Company’s use of your information, you should check that Company’s privacy policy before disclosing. Of course, please feel free to not provide the required information and not proceed with the activity. But please understand, once released by you to a Company, we cannot control use of your information by that Company.
                        </p>
                        <p>
                            Combining Information and Contractors. We may combine the information that we collect from you on our Site with information that you provide to us in connection with your use of our other products, services and web sites. We may use third party web site management and advertising contractors (the “Contractors”) to deliver advertisements to you on our behalf, contact you on our behalf, facilitate our site’s services, or to otherwise help us in providing the site. We may provide these contractors with your personally identifiable information solely for the purpose of providing these services to us or on our behalf. These Contractors may not disclose your information in violation of this Policy, which does not restrict Contractors disclosing your Internet protocol address, geographic location, Internet browser, network and connection, referring sites, ads and keywords used to arrive at the Site, pages you visited and time spent on the Site, your Internet host, operating system and connection speed, and additional information regarding your use of the Site.
                        </p>
                        <p>
                            Collection, Use and Disclosure. We may use your information to contact you regarding a transaction you have made on our Site. We also collect general data to analyze statistics on our Site’s user visits, purchases (if any), backgrounds and demographics, which we only release to others on an aggregate basis that does not reveal your personal information without your approval, except to: (1) the Companies; (2) the Contractors; (3) a company that may acquire our Site or controlling equity shares in our company, and (4) others when necessary to enforce the Terms of Use, any agreement we may have with you, or when we believe we are required to do so by law. We use this data to enhance our provision of services to you and our users, to improve our Site to best meet your interests, to measure visits to different areas of our Site, to contact you regarding updates to HXOUSE features that you use and to verify the number of users that have seen or clicked advertising on our Site.
                        </p>
                        <p>
                            Children. Our Site is not intended for children under the age of 13 years of age. We do not knowingly collect information from children under the age of 13. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our privacy policy by instructing their children to never provide information on our Site.
                        </p>
                        <p>
                            Cookies. Our Site may place a “cookie” in the browser files of your computer. This cookie does not collect information that personally identifies you as an individual (other than your Internet protocol address), but merely allows us to recognize your repeated visits to our Site. We use our cookie collected information to make your visit to our Site more enjoyable. If you want to disable cookies, there is a simple procedure in most Internet browsers that allows you to turn off or delete cookies, but please remember that cookies may be required to allow you to use certain features of our Site.
                        </p>
                        <p>
                            Links. Our Site may contain links to other websites. We do not control these other websites, and you should consult those other websites' privacy policies regarding their use of your information.
                        </p>
                    </Block>
                    <Block>
                        <h1>2. CHOICE</h1>
                        <p>
                            You may delete your profile and cease to use the App at any time.
                        </p>
                        <p>
                            Necessary Communications. Notwithstanding your choice to opt out of our service, we of course reserve the right to contact you regarding this Policy, the Terms of Use, your account status, and any other matters relevant to your use of the Site.
                        </p>
                    </Block>
                    <Block>
                        <h1>3. ACCESS</h1>
                        <p>
                            You can access your Site profile at your convenience to revise or correct errors in your personal information (unless such revisions or corrections may compromise privacy or security concerns).
                        </p>
                    </Block>
                    <Block>
                        <h1>4. SECURITY</h1>
                        <p>
                            Security Measures. We maintain security measures to protect your personal information from unauthorized access, misuse or disclosure.
                        </p>
                        <p>
                            Sharing. You should remember that when you use our Site to post content, comments and share additional information, any information that you provide is not secure and can be collected and used by others. As a result, you should exercise caution before you make such disclosures.
                        </p>
                    </Block>
                </Wrapper>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
